import { useCallback, useMemo } from "react";

import { AnyObject } from "@hen/stdlib/types";
import { UseMutationResult } from "react-query";

import {
  mergeEntityMapManifestInQueryCache,
  resolveObjectPoolTransactionsInQueryCache,
  useBoundQueryClient,
} from "store/objectPool/queryCache";
import { ObjectPoolModelName } from "utils/types";

import { useMeasureDuration } from "../../utils/measureInteraction";
import { trpcVanilla } from "../../utils/trpc";

export function useResolveObjectPoolTransactions() {
  const boundQueryClient = useBoundQueryClient();
  const measureDuration = useMeasureDuration("resolve_object_pool_transactions");
  const resolveObjectPoolTransactions = useCallback(
    // @ts-expect-error TS7006
    (transactions) => {
      measureDuration.start();
      resolveObjectPoolTransactionsInQueryCache(boundQueryClient, transactions);
      measureDuration.complete();
    },
    [boundQueryClient],
  );
  return resolveObjectPoolTransactions;
}

function throwOnAttemptMutate() {
  throw new Error(
    "useTransactionalMutation should not be used with mutate - use mutateAsync instead",
  );
}

export function useLegacyNonMetaTransactionalMutation<TMutation extends UseMutationResult>(
  trpcMutation: TMutation,
) {
  const resolveObjectPoolTransactions = useResolveObjectPoolTransactions();
  const mutateAsync = useCallback(
    // @ts-expect-error TS7006
    async (mutationArgs) => {
      const transactions = await trpcMutation.mutateAsync(mutationArgs);
      resolveObjectPoolTransactions(transactions);
    },
    [resolveObjectPoolTransactions, trpcMutation],
  );
  const result = useMemo(
    () => ({
      ...trpcMutation,
      mutate: throwOnAttemptMutate,
      mutateAsync,
    }),
    [mutateAsync, trpcMutation],
  );
  return result;
}
export function useTransactionalMutation<TMutation extends UseMutationResult>(
  trpcMutation: TMutation,
) {
  const resolveObjectPoolTransactions = useResolveObjectPoolTransactions();
  const mutateAsync = useCallback(
    // @ts-expect-error TS7006
    async (mutationArgs) => {
      // @ts-expect-error TS2339
      const { transactions, meta } = await trpcMutation.mutateAsync(mutationArgs);
      resolveObjectPoolTransactions(transactions);
      return meta;
    },
    [resolveObjectPoolTransactions, trpcMutation],
  );
  const result = useMemo(
    () => ({
      ...trpcMutation,
      mutate: throwOnAttemptMutate,
      mutateAsync,
    }),
    [mutateAsync, trpcMutation],
  );
  return result;
}

export function useObjectPoolInvalidation() {
  const boundQueryClient = useBoundQueryClient();

  const invalidateAsync = useCallback(
    async (models: Array<ObjectPoolModelName>) => {
      const { companyId } = boundQueryClient;
      const result = await trpcVanilla.query("object.manifest.findAll", {
        where: { companyId },
        models,
      });
      mergeEntityMapManifestInQueryCache(boundQueryClient, result);
    },
    [boundQueryClient],
  );

  const updateAsync = useCallback(
    async <T extends Array<ObjectPoolModelName>>(
      models: T,
      params?: { [key in T[number]]?: AnyObject },
    ) => {
      const { companyId } = boundQueryClient;
      const transactions = await trpcVanilla.query("object.manifest.updates.findAll", {
        where: { companyId },
        models,
        params,
        options: { updateOnly: true },
      });
      resolveObjectPoolTransactionsInQueryCache(boundQueryClient, transactions);
    },
    [boundQueryClient],
  );

  const result = useMemo(
    () => ({
      invalidateAsync,
      updateAsync,
    }),
    [invalidateAsync, updateAsync],
  );

  return result;
}
