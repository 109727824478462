import { CSSVariablesResolver } from "@mantine/core";

// Custom - design (after review) calls for 20px spacing but our system
// includes only 16 and 24, so we override. :(
export const SIDENAV_SPACING_Y_PX = 20;
export const SIDENAV_LOGO_HEIGHT_PX = 40;

const staticVariables: {
  [key: string]: string;
} = {
  "--sidenav-spacing-y": `${SIDENAV_SPACING_Y_PX}px`,
  "--sidenav-logo-height": `${SIDENAV_LOGO_HEIGHT_PX}px`,
};

export const cssVariablesResolver: CSSVariablesResolver = () => ({
  variables: staticVariables,
  light: staticVariables,
  dark: staticVariables,
});
