import { SegmentedControl } from "@mantine/core";

import { variantColorResolver } from "ui/theme/variantColorResolvers";

import classes from "./SegmentedControl.module.css";

export const segmentedControlComponentSettings = SegmentedControl.extend({
  defaultProps: {
    // TODO: there seems to be a few janky ui issues with SegmentedControls
    // including: https://github.com/mantinedev/mantine/issues/2454
    // 'removeing' the animation for now
    transitionDuration: 0,
  },
  vars: (theme, props) => {
    if (props.variant === "buttons") {
      const resolvedColors = variantColorResolver({
        color: props.color ?? "lime",
        theme,
        variant: "outline-light",
      });

      const inactiveColors = variantColorResolver({
        color: "gray",
        theme,
        variant: "outline",
      });

      return {
        root: {
          "--sc-color": resolvedColors.color,
          "--sc-color-hover": resolvedColors.hover,
          "--sc-color-active-bg": resolvedColors.background,
          "--sc-color-active-border": resolvedColors.border,

          "--sc-color-inactive": inactiveColors.color,
          "--sc-color-inactive-bg": inactiveColors.background,
          "--sc-color-inactive-border": inactiveColors.border,
        },
      };
    }

    return { root: {} };
  },
  classNames: classes,
});

// const getButtonVariantSize = (size: MantineSize) => {
//   switch (size) {
//     case "xs":
//       return "var(--mantine-font-size-xs)";
//     case "sm":
//       return "var(--mantine-font-size-sm)";
//     case "md":
//       return "var(--mantine-font-size-md)";
//     case "lg":
//       return "var(--mantine-font-size-lg)";
//     case "xl":
//       return "var(--mantine-font-size-xl)";
//     default:
//       return "var(--mantine-font-size-sm)";
//   }
// };

// const getButtonVariantPadding = (size: MantineSize) => {
//   switch (size) {
//     case "xs":
//       return "var(--mantine-spacing-xs) var(--mantine-spacing-sm)";
//     case "sm":
//       return "var(--mantine-spacing-sm) var(--mantine-spacing-md)";
//     case "md":
//       return "var(--mantine-spacing-md) var(--mantine-spacing-lg)";
//     case "lg":
//       return "var(--mantine-spacing-lg) var(--mantine-spacing-xl)";
//     case "xl":
//       return "var(--mantine-spacing-xl) var(--mantine-spacing-xxl)";
//     default:
//       return "var(--mantine-spacing-sm) var(--mantine-spacing-md)";
//   }
// };
