
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.14.0
 * Query Engine version: e9771e62de70f79a5e1c604a2d7c8e2a0a874b48
 */
Prisma.prismaVersion = {
  client: "5.14.0",
  engine: "e9771e62de70f79a5e1c604a2d7c8e2a0a874b48"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`NotFoundError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.AuditLogScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  modelType: 'modelType',
  modelInstance: 'modelInstance',
  versionActiveEnd: 'versionActiveEnd',
  versionBaseRecordId: 'versionBaseRecordId',
  versionCreatedBy: 'versionCreatedBy',
  modelDiff: 'modelDiff',
  changeSummary: 'changeSummary',
  companyId: 'companyId'
};

exports.Prisma.ApplicationSettingScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  key: 'key',
  value: 'value'
};

exports.Prisma.AsyncTaskScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  type: 'type',
  status: 'status',
  data: 'data',
  logs: 'logs',
  createdAt: 'createdAt',
  completedAt: 'completedAt'
};

exports.Prisma.AccessProfileScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  name: 'name',
  companyId: 'companyId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  policyData: 'policyData',
  description: 'description',
  autoMembershipRules: 'autoMembershipRules'
};

exports.Prisma.AccessProfileMemberRelationshipScalarFieldEnum = {
  explicitKind: 'explicitKind',
  accessProfileId: 'accessProfileId',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  companyId: 'companyId',
  id: 'id'
};

exports.Prisma.BenefitScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  name: 'name',
  adminLabel: 'adminLabel',
  amountType: 'amountType',
  amountCents: 'amountCents',
  amountCurrencyCode: 'amountCurrencyCode',
  amountPercent: 'amountPercent',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  amountIsMax: 'amountIsMax',
  excludeFromTotal: 'excludeFromTotal',
  benefitsPackageId: 'benefitsPackageId',
  source: 'source',
  amountFrequency: 'amountFrequency'
};

exports.Prisma.BenefitsPackageScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  name: 'name',
  adminLabel: 'adminLabel',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  description: 'description'
};

exports.Prisma.BenefitAssignmentScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  benefitId: 'benefitId',
  companyId: 'companyId',
  userId: 'userId'
};

exports.Prisma.CartaEquityGrantScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  userId: 'userId',
  securityId: 'securityId',
  sharesIssued: 'sharesIssued',
  awardType: 'awardType',
  exercisePrice: 'exercisePrice',
  sharesExercised: 'sharesExercised',
  marketPriceAtIssue: 'marketPriceAtIssue',
  vestingSchedule: 'vestingSchedule',
  vestingStartDate: 'vestingStartDate',
  vestingEndDate: 'vestingEndDate',
  canceledDate: 'canceledDate',
  issueDate: 'issueDate',
  companyId: 'companyId',
  cumulativeVested: 'cumulativeVested',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CompanyScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  name: 'name',
  legalName: 'legalName',
  ein: 'ein',
  rangeSource: 'rangeSource',
  enableTaxFields: 'enableTaxFields',
  taxContributionPlanUrl: 'taxContributionPlanUrl',
  showLevelInAwardLetter: 'showLevelInAwardLetter',
  logo: 'logo',
  weeklyWorkingHours: 'weeklyWorkingHours',
  accessPolicy: 'accessPolicy',
  isOnboarding: 'isOnboarding',
  isTestCompany: 'isTestCompany',
  payRangeType: 'payRangeType',
  employeeDashboardSettings: 'employeeDashboardSettings',
  enableEmployeeDashboard: 'enableEmployeeDashboard',
  enableEmployeeDashboardAccess: 'enableEmployeeDashboardAccess',
  oktaCompanySlug: 'oktaCompanySlug',
  dateFormat: 'dateFormat',
  defaultCurrency: 'defaultCurrency',
  locale: 'locale',
  localeSettings: 'localeSettings',
  payPointSettings: 'payPointSettings',
  defaultAccessProfileId: 'defaultAccessProfileId',
  defaultReviewAdminAccessProfileId: 'defaultReviewAdminAccessProfileId',
  defaultReviewApproverAccessProfileId: 'defaultReviewApproverAccessProfileId',
  defaultReviewPartnerAccessProfileId: 'defaultReviewPartnerAccessProfileId',
  defaultReviewProposerAccessProfileId: 'defaultReviewProposerAccessProfileId',
  oktaClientId: 'oktaClientId',
  oktaClientSecret: 'oktaClientSecret',
  oktaHostname: 'oktaHostname',
  enableAdminBenchmarking: 'enableAdminBenchmarking',
  enableAdminMercerBenchmarking: 'enableAdminMercerBenchmarking',
  enableAdminComptryxBenchmarking: 'enableAdminComptryxBenchmarking',
  isTrialSandbox: 'isTrialSandbox',
  showCompensationHistory: 'showCompensationHistory',
  enabledSourcesForCompensationHistory: 'enabledSourcesForCompensationHistory',
  microsoftEntraTenantId: 'microsoftEntraTenantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  partnerId: 'partnerId',
  partnerGovernanceExempt: 'partnerGovernanceExempt',
  cycleCadence: 'cycleCadence',
  glossaryTerms: 'glossaryTerms',
  suppressEmployeeEmails: 'suppressEmployeeEmails',
  stockSymbol: 'stockSymbol',
  stockExchange: 'stockExchange',
  expectedNextCycleDate: 'expectedNextCycleDate',
  churnDate: 'churnDate',
  contractStartDate: 'contractStartDate',
  includeBonusTargetInOTECalculation: 'includeBonusTargetInOTECalculation',
  autoCalculateVariableTargetIncrease: 'autoCalculateVariableTargetIncrease',
  onboardingConfigId: 'onboardingConfigId',
  featureFlags: 'featureFlags'
};

exports.Prisma.CompanyOnboardingConfigScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  completedAt: 'completedAt',
  companyId: 'companyId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  viewedAnalyticsAt: 'viewedAnalyticsAt',
  viewedAccessProfilesAt: 'viewedAccessProfilesAt',
  viewedCustomFieldsAt: 'viewedCustomFieldsAt',
  postponedTotalRewardsSetupAt: 'postponedTotalRewardsSetupAt',
  postponedPerformanceRatingsSetupAt: 'postponedPerformanceRatingsSetupAt'
};

exports.Prisma.CustomFieldScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  label: 'label',
  description: 'description',
  model: 'model',
  valueType: 'valueType',
  isEditable: 'isEditable',
  options: 'options',
  sourceType: 'sourceType',
  sourceSubType: 'sourceSubType',
  createAwardLetterTemplateSection: 'createAwardLetterTemplateSection',
  enableSuggestions: 'enableSuggestions',
  isRequired: 'isRequired',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CompensationApprovalScalarFieldEnum = {
  explicitKind: 'explicitKind',
  reviewId: 'reviewId',
  proposerId: 'proposerId',
  approverId: 'approverId',
  isDirect: 'isDirect',
  status: 'status',
  approvedAt: 'approvedAt',
  approvedByName: 'approvedByName',
  approvedById: 'approvedById',
  rejectionReason: 'rejectionReason',
  rejectedAt: 'rejectedAt',
  rejectedByName: 'rejectedByName',
  rejectedById: 'rejectedById',
  id: 'id',
  proposerBudgetId: 'proposerBudgetId',
  companyId: 'companyId',
  explicitApprovalLevelId: 'explicitApprovalLevelId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.CompensationApprovalLevelScalarFieldEnum = {
  explicitKind: 'explicitKind',
  reviewId: 'reviewId',
  approverId: 'approverId',
  parentId: 'parentId',
  depth: 'depth',
  id: 'id',
  companyId: 'companyId',
  explicitParentId: 'explicitParentId',
  bonusBudget: 'bonusBudget',
  equityBudget: 'equityBudget',
  raiseBudget: 'raiseBudget',
  computedAggregates: 'computedAggregates',
  raiseBudgetInputMode: 'raiseBudgetInputMode'
};

exports.Prisma.EmailAddressScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  userId: 'userId',
  email: 'email',
  isPrimary: 'isPrimary',
  confirmedAt: 'confirmedAt',
  confirmedCorrectForId: 'confirmedCorrectForId',
  companyId: 'companyId',
  snapshotCycleId: 'snapshotCycleId',
  versionBaseRecordId: 'versionBaseRecordId'
};

exports.Prisma.ExchangeRateScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  from: 'from',
  to: 'to',
  rate: 'rate',
  date: 'date'
};

exports.Prisma.StockPriceScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  exchange: 'exchange',
  symbol: 'symbol',
  currency: 'currency',
  date: 'date',
  priceCents: 'priceCents',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.TableSettingsScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  tableName: 'tableName',
  companyId: 'companyId',
  config: 'config',
  updatedAt: 'updatedAt',
  createdAt: 'createdAt'
};

exports.Prisma.UserTableStateScalarFieldEnum = {
  explicitKind: 'explicitKind',
  userId: 'userId',
  reviewId: 'reviewId',
  config: 'config',
  id: 'id',
  companyId: 'companyId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  googleId: 'googleId',
  companyId: 'companyId',
  securePassword: 'securePassword',
  name: 'name',
  role: 'role',
  superUserRole: 'superUserRole',
  governedPartnerId: 'governedPartnerId',
  isNonEmployee: 'isNonEmployee',
  worksForComprehensive: 'worksForComprehensive',
  lastLogin: 'lastLogin',
  lastActive: 'lastActive',
  avatarUrl: 'avatarUrl',
  status: 'status',
  intercomSessionHash: 'intercomSessionHash',
  integrationUserId: 'integrationUserId',
  paginationPageSize: 'paginationPageSize',
  defaultCurrency: 'defaultCurrency',
  privacyPolicyConsentDate: 'privacyPolicyConsentDate',
  legalName: 'legalName',
  legacyCompanyId: 'legacyCompanyId',
  legacyUserId: 'legacyUserId',
  legacyManagerId: 'legacyManagerId',
  managerId: 'managerId',
  legacyFinchId: 'legacyFinchId',
  legacyName: 'legacyName',
  title: 'title',
  department: 'department',
  employmentType: 'employmentType',
  employmentSubType: 'employmentSubType',
  startDate: 'startDate',
  endDate: 'endDate',
  salary: 'salary',
  salaryEffectiveDate: 'salaryEffectiveDate',
  salaryIncomeFrequency: 'salaryIncomeFrequency',
  salaryCurrencyCode: 'salaryCurrencyCode',
  shares: 'shares',
  zone: 'zone',
  industry: 'industry',
  bonusTargetPercent: 'bonusTargetPercent',
  variableTarget: 'variableTarget',
  performanceRating: 'performanceRating',
  priorPerformanceRating: 'priorPerformanceRating',
  salaryNote: 'salaryNote',
  race: 'race',
  gender: 'gender',
  metadata: 'metadata',
  employeeId: 'employeeId',
  city: 'city',
  country: 'country',
  state: 'state',
  legacyLegalName: 'legacyLegalName',
  lastGrantDate: 'lastGrantDate',
  rangeId: 'rangeId',
  isTerminated: 'isTerminated',
  extra: 'extra',
  fieldSources: 'fieldSources',
  oktaId: 'oktaId',
  versionBaseRecordId: 'versionBaseRecordId',
  bonusTargetNominal: 'bonusTargetNominal',
  bonusTargetInputMode: 'bonusTargetInputMode',
  snapshotCycleId: 'snapshotCycleId',
  customFieldValues: 'customFieldValues',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  enableCycleEventEmail: 'enableCycleEventEmail'
};

exports.Prisma.RangeScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  legacyZone: 'legacyZone',
  industry: 'industry',
  code: 'code',
  title: 'title',
  legacyFamily: 'legacyFamily',
  legacyTrack: 'legacyTrack',
  legacyLevel: 'legacyLevel',
  salaryCurrencyCode: 'salaryCurrencyCode',
  baseSalary: 'baseSalary',
  totalPay: 'totalPay',
  equity: 'equity',
  equityMetadata: 'equityMetadata',
  salaryIncomeFrequency: 'salaryIncomeFrequency',
  zoneId: 'zoneId',
  extra: 'extra',
  jobId: 'jobId',
  grantUnit: 'grantUnit',
  hireGrantDisplayName: 'hireGrantDisplayName',
  promoGrantDisplayName: 'promoGrantDisplayName',
  refreshGrantDisplayName: 'refreshGrantDisplayName',
  vestingCliff: 'vestingCliff',
  vestingPeriod: 'vestingPeriod',
  vestingSchedule: 'vestingSchedule',
  tag: 'tag',
  payPoints: 'payPoints',
  versionBaseRecordId: 'versionBaseRecordId',
  metadata: 'metadata',
  snapshotCycleId: 'snapshotCycleId',
  customFieldValues: 'customFieldValues',
  includeBonusTargetInCompaRatioCalculation: 'includeBonusTargetInCompaRatioCalculation',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ZoneScalarFieldEnum = {
  explicitKind: 'explicitKind',
  name: 'name',
  id: 'id',
  geofactor: 'geofactor',
  companyId: 'companyId',
  extra: 'extra',
  description: 'description',
  versionBaseRecordId: 'versionBaseRecordId',
  snapshotCycleId: 'snapshotCycleId'
};

exports.Prisma.LevelScalarFieldEnum = {
  explicitKind: 'explicitKind',
  name: 'name',
  id: 'id',
  companyId: 'companyId',
  extra: 'extra',
  seniority: 'seniority',
  trackId: 'trackId',
  description: 'description',
  versionBaseRecordId: 'versionBaseRecordId',
  snapshotCycleId: 'snapshotCycleId'
};

exports.Prisma.TrackScalarFieldEnum = {
  explicitKind: 'explicitKind',
  name: 'name',
  id: 'id',
  companyId: 'companyId',
  extra: 'extra',
  description: 'description',
  versionBaseRecordId: 'versionBaseRecordId',
  snapshotCycleId: 'snapshotCycleId'
};

exports.Prisma.JobScalarFieldEnum = {
  explicitKind: 'explicitKind',
  name: 'name',
  code: 'code',
  id: 'id',
  companyId: 'companyId',
  extra: 'extra',
  familyId: 'familyId',
  levelId: 'levelId',
  description: 'description',
  versionBaseRecordId: 'versionBaseRecordId',
  snapshotCycleId: 'snapshotCycleId'
};

exports.Prisma.FamilyScalarFieldEnum = {
  explicitKind: 'explicitKind',
  name: 'name',
  id: 'id',
  companyId: 'companyId',
  extra: 'extra',
  description: 'description',
  versionBaseRecordId: 'versionBaseRecordId',
  familyGroupId: 'familyGroupId',
  snapshotCycleId: 'snapshotCycleId'
};

exports.Prisma.FamilyGroupScalarFieldEnum = {
  explicitKind: 'explicitKind',
  name: 'name',
  id: 'id',
  companyId: 'companyId',
  extra: 'extra',
  description: 'description',
  versionBaseRecordId: 'versionBaseRecordId',
  snapshotCycleId: 'snapshotCycleId'
};

exports.Prisma.ProposalScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  reviewId: 'reviewId',
  proposerId: 'proposerId',
  suggestedRaise: 'suggestedRaise',
  suggestedBonus: 'suggestedBonus',
  raise: 'raise',
  raisePercent: 'raisePercent',
  raiseInputMode: 'raiseInputMode',
  variableTarget: 'variableTarget',
  adjustVariableTarget: 'adjustVariableTarget',
  bonus: 'bonus',
  bonusPercent: 'bonusPercent',
  bonusInputMode: 'bonusInputMode',
  title: 'title',
  justification: 'justification',
  notes: 'notes',
  cartaEquityGrants: 'cartaEquityGrants',
  suggestedRaiseNote: 'suggestedRaiseNote',
  suggestedBonusNote: 'suggestedBonusNote',
  awardLetterStatus: 'awardLetterStatus',
  suppressAwardLetter: 'suppressAwardLetter',
  awardLetterPdf: 'awardLetterPdf',
  suggestedRaiseInputMode: 'suggestedRaiseInputMode',
  suggestedRaisePercent: 'suggestedRaisePercent',
  ownerId: 'ownerId',
  eligibleForEquity: 'eligibleForEquity',
  eligibleForPromotion: 'eligibleForPromotion',
  suggestedBonusInputMode: 'suggestedBonusInputMode',
  suggestedBonusPercent: 'suggestedBonusPercent',
  oteIncrease: 'oteIncrease',
  oteIncreaseInputMode: 'oteIncreaseInputMode',
  oteIncreasePercent: 'oteIncreasePercent',
  companyId: 'companyId',
  rangeId: 'rangeId',
  managerId: 'managerId',
  proposerBudgetId: 'proposerBudgetId',
  adjustBonusTarget: 'adjustBonusTarget',
  bonusTargetPercent: 'bonusTargetPercent',
  groupAchievementRating: 'groupAchievementRating',
  individualAchievementRating: 'individualAchievementRating',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  bonusTargetNominal: 'bonusTargetNominal',
  bonusTargetInputMode: 'bonusTargetInputMode',
  suggestedBonusTarget: 'suggestedBonusTarget',
  suggestedBonusTargetNominal: 'suggestedBonusTargetNominal',
  suggestedBonusTargetPercent: 'suggestedBonusTargetPercent',
  eligibleForBonus: 'eligibleForBonus',
  eligibleForSalary: 'eligibleForSalary',
  payComponentRatios: 'payComponentRatios',
  lockPayComponentRatios: 'lockPayComponentRatios',
  customFieldValues: 'customFieldValues',
  logicSuggestedValues: 'logicSuggestedValues',
  awardLetterSentAt: 'awardLetterSentAt',
  grants: 'grants',
  outlierData: 'outlierData',
  preventAutoAccept: 'preventAutoAccept',
  awardLetterAcknowledgedAt: 'awardLetterAcknowledgedAt'
};

exports.Prisma.GrantTypeScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  name: 'name',
  companyId: 'companyId',
  vestingSchedule: 'vestingSchedule',
  vestingPeriod: 'vestingPeriod',
  vestingCliff: 'vestingCliff',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ProposerBudgetScalarFieldEnum = {
  explicitKind: 'explicitKind',
  reviewId: 'reviewId',
  proposerId: 'proposerId',
  raiseBudget: 'raiseBudget',
  bonusBudget: 'bonusBudget',
  submittedAt: 'submittedAt',
  raiseBudgetInputMode: 'raiseBudgetInputMode',
  raiseBudgetPercent: 'raiseBudgetPercent',
  bonusBudgetInputMode: 'bonusBudgetInputMode',
  bonusBudgetPercent: 'bonusBudgetPercent',
  isSubmitted: 'isSubmitted',
  equityBudget: 'equityBudget',
  id: 'id',
  companyId: 'companyId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  computedAggregates: 'computedAggregates'
};

exports.Prisma.ReviewScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  createdById: 'createdById',
  endedById: 'endedById',
  name: 'name',
  status: 'status',
  createdAt: 'createdAt',
  cycleStartEmailsSentAt: 'cycleStartEmailsSentAt',
  cycleEndEmailsSentAt: 'cycleEndEmailsSentAt',
  startedAt: 'startedAt',
  endedAt: 'endedAt',
  exportedAt: 'exportedAt',
  salaryBenchmarkTargets: 'salaryBenchmarkTargets',
  equityBenchmarkTargets: 'equityBenchmarkTargets',
  salaryBenchmarkType: 'salaryBenchmarkType',
  enablePromotions: 'enablePromotions',
  enableSalary: 'enableSalary',
  enableBonus: 'enableBonus',
  enableEquity: 'enableEquity',
  defaultInputMode: 'defaultInputMode',
  awardLetterPostface: 'awardLetterPostface',
  awardLetterPreface: 'awardLetterPreface',
  salaryIncreaseType: 'salaryIncreaseType',
  awardLettersReleased: 'awardLettersReleased',
  metadataItems: 'metadataItems',
  defaultCurrency: 'defaultCurrency',
  exchangeRates: 'exchangeRates',
  exchangeRateDate: 'exchangeRateDate',
  currentValuePerShare: 'currentValuePerShare',
  showEquityAsMonetaryValueInAwardLetters: 'showEquityAsMonetaryValueInAwardLetters',
  showEquityMonetaryValue: 'showEquityMonetaryValue',
  managersCanViewAwardLetters: 'managersCanViewAwardLetters',
  awardLettersZipFileUrl: 'awardLettersZipFileUrl',
  awardLettersZipFileGeneratedAt: 'awardLettersZipFileGeneratedAt',
  summaryFileUploadId: 'summaryFileUploadId',
  updatedAt: 'updatedAt',
  computedBonusMode: 'computedBonusMode',
  adminAccessProfileId: 'adminAccessProfileId',
  approverAccessProfileId: 'approverAccessProfileId',
  partnerAccessProfileId: 'partnerAccessProfileId',
  proposerAccessProfileId: 'proposerAccessProfileId',
  setupConfigId: 'setupConfigId',
  enabledSuggestionComponents: 'enabledSuggestionComponents',
  autoFillSettings: 'autoFillSettings',
  allowBonusTargetTypeChange: 'allowBonusTargetTypeChange',
  enableBonusTarget: 'enableBonusTarget',
  fileOnlyMode: 'fileOnlyMode',
  selectedPerformanceCycles: 'selectedPerformanceCycles',
  changeEffectiveDate: 'changeEffectiveDate',
  enableEventNotificationEmails: 'enableEventNotificationEmails',
  requireNotesForPromotions: 'requireNotesForPromotions',
  requireNotesForPromotionsConfig: 'requireNotesForPromotionsConfig',
  requireNotesForSalaryChangeAboveSuggestion: 'requireNotesForSalaryChangeAboveSuggestion',
  userSnapshotDate: 'userSnapshotDate',
  estimatedStartDate: 'estimatedStartDate',
  grantTypeIds: 'grantTypeIds',
  salaryBudget: 'salaryBudget',
  equityBudget: 'equityBudget',
  bonusBudget: 'bonusBudget',
  computedAggregates: 'computedAggregates',
  awardLetterAckSettings: 'awardLetterAckSettings',
  snapshottingStartedAt: 'snapshottingStartedAt',
  snapshottingEndedAt: 'snapshottingEndedAt',
  snapshottingFailedAt: 'snapshottingFailedAt'
};

exports.Prisma.ReviewSetupConfigScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  basicLogicForm: 'basicLogicForm',
  basicLogicFormInitialValuesSavedAt: 'basicLogicFormInitialValuesSavedAt',
  ineligibilityRules: 'ineligibilityRules',
  ineligibilityRulesAppliedAt: 'ineligibilityRulesAppliedAt',
  assignmentRulesAppliedAt: 'assignmentRulesAppliedAt',
  assignmentRules: 'assignmentRules',
  overviewForm: 'overviewForm',
  budgetSettingsSavedAt: 'budgetSettingsSavedAt',
  suggestionsSettingsSavedAt: 'suggestionsSettingsSavedAt',
  approvals: 'approvals',
  usePerformanceRatings: 'usePerformanceRatings',
  usePerformanceQuestion: 'usePerformanceQuestion',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  overviewSettingsConfirmedAt: 'overviewSettingsConfirmedAt',
  viewsSettingsConfirmedAt: 'viewsSettingsConfirmedAt',
  awardLetterViewsSettingsConfirmedAt: 'awardLetterViewsSettingsConfirmedAt',
  awardLetterTemplateSettingsConfirmedAt: 'awardLetterTemplateSettingsConfirmedAt'
};

exports.Prisma.AwardLetterTemplateScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  reviewId: 'reviewId',
  intro: 'intro',
  preface: 'preface',
  postface: 'postface',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.AwardLetterTemplateSectionScalarFieldEnum = {
  explicitKind: 'explicitKind',
  templateId: 'templateId',
  type: 'type',
  typeSortOrder: 'typeSortOrder',
  subType: 'subType',
  subTypeSortOrder: 'subTypeSortOrder',
  header: 'header',
  body: 'body',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  companyId: 'companyId',
  id: 'id'
};

exports.Prisma.AwardLetterScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  reviewId: 'reviewId',
  userId: 'userId',
  templateId: 'templateId',
  status: 'status',
  s3Url: 's3Url',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  companyId: 'companyId'
};

exports.Prisma.IntegrationScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  displayName: 'displayName',
  externalCompanyId: 'externalCompanyId',
  integrationType: 'integrationType',
  provider: 'provider',
  createdAt: 'createdAt',
  createdBy: 'createdBy',
  autoSync: 'autoSync',
  autoSyncOrder: 'autoSyncOrder',
  dataType: 'dataType',
  credentials: 'credentials',
  settings: 'settings',
  lastRefreshTime: 'lastRefreshTime',
  lastSyncTime: 'lastSyncTime'
};

exports.Prisma.AccessExtensionScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  rangeId: 'rangeId',
  createdAt: 'createdAt',
  createdById: 'createdById',
  meta: 'meta',
  userId: 'userId'
};

exports.Prisma.AdminTaskScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  type: 'type',
  status: 'status',
  metadata: 'metadata',
  emailId: 'emailId'
};

exports.Prisma.FileUploadScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  uploadType: 'uploadType',
  uploaderType: 'uploaderType',
  uploadedById: 'uploadedById',
  type: 'type',
  bucket: 'bucket',
  key: 'key',
  name: 'name'
};

exports.Prisma.DataImportScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  status: 'status',
  changeFileS3Url: 'changeFileS3Url',
  rawDataFileS3Url: 'rawDataFileS3Url',
  externalJobId: 'externalJobId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  metrics: 'metrics',
  source: 'source',
  type: 'type',
  integrationId: 'integrationId'
};

exports.Prisma.LogicRunScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  type: 'type',
  status: 'status',
  error: 'error',
  metrics: 'metrics',
  logs: 'logs',
  extra: 'extra',
  opts: 'opts',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PerformanceCycleScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  provider: 'provider',
  integrationCycleId: 'integrationCycleId',
  name: 'name',
  snapshotCycleId: 'snapshotCycleId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  status: 'status',
  versionBaseRecordId: 'versionBaseRecordId'
};

exports.Prisma.PerformanceRatingScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  performanceCycleId: 'performanceCycleId',
  userId: 'userId',
  integrationRatingId: 'integrationRatingId',
  integrationUserId: 'integrationUserId',
  integrationManagerId: 'integrationManagerId',
  title: 'title',
  value: 'value',
  score: 'score',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  versionBaseRecordId: 'versionBaseRecordId',
  snapshotCycleId: 'snapshotCycleId'
};

exports.Prisma.ReviewTableFilterStateScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  userId: 'userId',
  reviewId: 'reviewId',
  companyId: 'companyId',
  filterState: 'filterState',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  proposerBudgetId: 'proposerBudgetId'
};

exports.Prisma.CompensationEventScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  source: 'source',
  eventType: 'eventType',
  eventDate: 'eventDate',
  userId: 'userId',
  metadata: 'metadata',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.SystemFieldOverrideScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  fieldId: 'fieldId',
  companyId: 'companyId',
  label: 'label',
  description: 'description',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.FieldTablePlacementScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  reviewId: 'reviewId',
  companyId: 'companyId',
  promotions: 'promotions',
  salary: 'salary',
  bonus: 'bonus',
  equity: 'equity',
  summary: 'summary',
  overview: 'overview',
  awardLetters: 'awardLetters',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.LogicGraphScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  reviewId: 'reviewId',
  logicType: 'logicType',
  companyId: 'companyId',
  nodeMap: 'nodeMap',
  editorSettings: 'editorSettings',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  editedThroughFlumeEditor: 'editedThroughFlumeEditor',
  customFieldId: 'customFieldId',
  upstreamCustomFieldIds: 'upstreamCustomFieldIds',
  upstreamReviewSuggestions: 'upstreamReviewSuggestions'
};

exports.Prisma.ObjectiveScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  versionBaseRecordId: 'versionBaseRecordId',
  snapshotCycleId: 'snapshotCycleId',
  companyId: 'companyId',
  userId: 'userId',
  parentObjectiveId: 'parentObjectiveId',
  integrationObjectiveId: 'integrationObjectiveId',
  integrationUserId: 'integrationUserId',
  integrationParentObjectiveId: 'integrationParentObjectiveId',
  description: 'description',
  scope: 'scope',
  startDate: 'startDate',
  endDate: 'endDate',
  status: 'status',
  percentage: 'percentage'
};

exports.Prisma.KeyResultScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  versionBaseRecordId: 'versionBaseRecordId',
  snapshotCycleId: 'snapshotCycleId',
  companyId: 'companyId',
  objectiveId: 'objectiveId',
  userId: 'userId',
  integrationUserId: 'integrationUserId',
  integrationKeyResultId: 'integrationKeyResultId',
  description: 'description',
  type: 'type',
  value: 'value',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PresenceScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  userId: 'userId',
  companyId: 'companyId',
  proposalId: 'proposalId',
  columnId: 'columnId',
  rowId: 'rowId',
  color: 'color',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PerformanceQuestionScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  snapshotCycleId: 'snapshotCycleId',
  reviewId: 'reviewId',
  integrationQuestionId: 'integrationQuestionId',
  title: 'title',
  overrideTitle: 'overrideTitle',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PerformanceAnswerScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  snapshotCycleId: 'snapshotCycleId',
  questionId: 'questionId',
  userId: 'userId',
  integrationQuestionId: 'integrationQuestionId',
  integrationAnswerId: 'integrationAnswerId',
  integrationAuthorId: 'integrationAuthorId',
  integrationParticipantId: 'integrationParticipantId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  status: 'status',
  value: 'value',
  metrics_value: 'metrics_value'
};

exports.Prisma.LLMPromptScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  key: 'key',
  prompt: 'prompt',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.LlmConversationScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  userId: 'userId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  lLMPromptId: 'lLMPromptId'
};

exports.Prisma.LlmConversationMessageScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  userId: 'userId',
  message: 'message',
  llmConversationId: 'llmConversationId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.DataImportApiLogScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  dataImportId: 'dataImportId',
  url: 'url',
  s3FileUrl: 's3FileUrl',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PartnerScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.BenchmarkingWorkflowScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  requestOptions: 'requestOptions',
  status: 'status',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.BenchmarkResultScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  userId: 'userId',
  benchmarkingWorkflowId: 'benchmarkingWorkflowId',
  benchmarkCandidate: 'benchmarkCandidate',
  benchmarkAssignment: 'benchmarkAssignment',
  resolvedBenchmarkData: 'resolvedBenchmarkData',
  approved: 'approved',
  employeeSalaryData: 'employeeSalaryData',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.AutoBenchmarkLlmCallScalarFieldEnum = {
  explicitKind: 'explicitKind',
  id: 'id',
  companyId: 'companyId',
  workflowId: 'workflowId',
  model: 'model',
  input: 'input',
  output: 'output',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.ManagerHierarchyScalarFieldEnum = {
  id: 'id',
  name: 'name',
  managerId: 'managerId',
  userRangeId: 'userRangeId',
  companyId: 'companyId',
  managerListNames: 'managerListNames',
  managerListIds: 'managerListIds',
  range_company_id: 'range_company_id',
  rangeId: 'rangeId',
  jobId: 'jobId',
  jobName: 'jobName',
  levelId: 'levelId',
  levelName: 'levelName',
  levelSeniority: 'levelSeniority',
  levelSeniorityPlusOne: 'levelSeniorityPlusOne',
  familyId: 'familyId',
  familyName: 'familyName',
  zoneId: 'zoneId',
  zoneName: 'zoneName',
  trackId: 'trackId',
  trackName: 'trackName'
};

exports.Prisma.RangesWithTaxonomyScalarFieldEnum = {
  id: 'id',
  rangeCompanyId: 'rangeCompanyId',
  rangeId: 'rangeId',
  jobId: 'jobId',
  jobName: 'jobName',
  levelId: 'levelId',
  levelName: 'levelName',
  levelSeniority: 'levelSeniority',
  levelSeniorityPlusOne: 'levelSeniorityPlusOne',
  familyId: 'familyId',
  familyName: 'familyName',
  zoneId: 'zoneId',
  zoneName: 'zoneName',
  trackId: 'trackId',
  trackName: 'trackName'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.JsonNullValueInput = {
  JsonNull: Prisma.JsonNull
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};
exports.TaskStatus = exports.$Enums.TaskStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
};

exports.BenefitAmountType = exports.$Enums.BenefitAmountType = {
  FIXED: 'FIXED',
  RELATIVE: 'RELATIVE'
};

exports.BenefitAmountFrequency = exports.$Enums.BenefitAmountFrequency = {
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY'
};

exports.RangeSource = exports.$Enums.RangeSource = {
  RADFORD: 'RADFORD',
  OPTION_IMPACT: 'OPTION_IMPACT',
  CUSTOM: 'CUSTOM'
};

exports.PayRangeType = exports.$Enums.PayRangeType = {
  OTE: 'OTE',
  SALARY: 'SALARY'
};

exports.PartnerId = exports.$Enums.PartnerId = {
  FIFTEEN_FIVE: 'FIFTEEN_FIVE'
};

exports.CompensationEventDataSourceType = exports.$Enums.CompensationEventDataSourceType = {
  UPLOAD: 'UPLOAD',
  INTEGRATION: 'INTEGRATION',
  APPLICATION: 'APPLICATION'
};

exports.FieldType = exports.$Enums.FieldType = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DOLLAR: 'DOLLAR',
  PERCENT: 'PERCENT',
  DATE: 'DATE',
  OPTIONS: 'OPTIONS'
};

exports.CustomFieldSourceType = exports.$Enums.CustomFieldSourceType = {
  COMPUTED: 'COMPUTED',
  IN_APP: 'IN_APP',
  INTEGRATION: 'INTEGRATION'
};

exports.CompensationApprovalStatus = exports.$Enums.CompensationApprovalStatus = {
  PENDING: 'PENDING',
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};

exports.VariableInputMode = exports.$Enums.VariableInputMode = {
  PERCENT: 'PERCENT',
  DOLLAR: 'DOLLAR'
};

exports.Role = exports.$Enums.Role = {
  EMPLOYEE: 'EMPLOYEE',
  COMPANY_ADMIN: 'COMPANY_ADMIN'
};

exports.SuperUserRole = exports.$Enums.SuperUserRole = {
  FULL_ACCESS: 'FULL_ACCESS',
  PARTNER_ADMIN: 'PARTNER_ADMIN'
};

exports.UserStatus = exports.$Enums.UserStatus = {
  PENDING: 'PENDING',
  INVITED: 'INVITED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED'
};

exports.SalaryIncomeFrequency = exports.$Enums.SalaryIncomeFrequency = {
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY',
  SEMI_MONTHLY: 'SEMI_MONTHLY',
  BI_WEEKLY: 'BI_WEEKLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
  HOURLY: 'HOURLY',
  FIXED: 'FIXED'
};

exports.AwardLetterStatus = exports.$Enums.AwardLetterStatus = {
  PENDING: 'PENDING',
  SENT: 'SENT'
};

exports.ReviewStatus = exports.$Enums.ReviewStatus = {
  SETUP: 'SETUP',
  IN_PROGRESS: 'IN_PROGRESS',
  ENDED: 'ENDED'
};

exports.SalaryBenchmarkType = exports.$Enums.SalaryBenchmarkType = {
  BASE_SALARY: 'BASE_SALARY',
  TOTAL_PAY: 'TOTAL_PAY'
};

exports.SalaryIncreaseType = exports.$Enums.SalaryIncreaseType = {
  BASE_SALARY: 'BASE_SALARY',
  TOTAL_PAY: 'TOTAL_PAY'
};

exports.IntegrationType = exports.$Enums.IntegrationType = {
  FINCH: 'FINCH',
  DIRECT: 'DIRECT'
};

exports.IntegrationProvider = exports.$Enums.IntegrationProvider = {
  ADP_WORKFORCE_NOW: 'ADP_WORKFORCE_NOW',
  BAMBOO_HR: 'BAMBOO_HR',
  BOB: 'BOB',
  GUSTO: 'GUSTO',
  HUMAANS: 'HUMAANS',
  INSPERITY: 'INSPERITY',
  JUSTWORKS: 'JUSTWORKS',
  NAMELY: 'NAMELY',
  PAYCHEX_FLEX: 'PAYCHEX_FLEX',
  PAYCOM: 'PAYCOM',
  PAYLOCITY: 'PAYLOCITY',
  PERSONIO: 'PERSONIO',
  QUICKBOOKS: 'QUICKBOOKS',
  RIPPLING: 'RIPPLING',
  ADP_RUN: 'ADP_RUN',
  SAGE_HR: 'SAGE_HR',
  SAPLING: 'SAPLING',
  SEQUOIA_ONE: 'SEQUOIA_ONE',
  SQUARE_PAYROLL: 'SQUARE_PAYROLL',
  TRINET: 'TRINET',
  ULTI_PRO: 'ULTI_PRO',
  WORKDAY: 'WORKDAY',
  ZENEFITS: 'ZENEFITS',
  AAP_ISOLVED: 'AAP_ISOLVED',
  ABACUS_ISOLVED: 'ABACUS_ISOLVED',
  ABSOLUTE_ISOLVED: 'ABSOLUTE_ISOLVED',
  ACCUPAY_ISOLVED: 'ACCUPAY_ISOLVED',
  ACE_ISOLVED: 'ACE_ISOLVED',
  ACCOUNTANTSWORLD: 'ACCOUNTANTSWORLD',
  ADAMS_KEEGAN: 'ADAMS_KEEGAN',
  ADP_COMPREHENSIVE: 'ADP_COMPREHENSIVE',
  ADP_TOTALSOURCE: 'ADP_TOTALSOURCE',
  ADVANTAGE_PAYROLL_SERVICES: 'ADVANTAGE_PAYROLL_SERVICES',
  AFFILIATED_HR_PAYROLL_SERVICES_ISOLVED: 'AFFILIATED_HR_PAYROLL_SERVICES_ISOLVED',
  AFFILIATED_HR_PAYROLL_SERVICES_EVOLUTION: 'AFFILIATED_HR_PAYROLL_SERVICES_EVOLUTION',
  AHOLA: 'AHOLA',
  ALPHASTAFF: 'ALPHASTAFF',
  AMPLIFY_HR: 'AMPLIFY_HR',
  APS_PAYROLL: 'APS_PAYROLL',
  ASSET_HR: 'ASSET_HR',
  ASURE_SOFTWARE: 'ASURE_SOFTWARE',
  BALANCE_POINT: 'BALANCE_POINT',
  BARON_ISOLVED: 'BARON_ISOLVED',
  BASIC: 'BASIC',
  BBCS: 'BBCS',
  BBSI: 'BBSI',
  BCN_SERVICES: 'BCN_SERVICES',
  BENECARE: 'BENECARE',
  BIG_FISH_EMPLOYER_SERVICES: 'BIG_FISH_EMPLOYER_SERVICES',
  BIZCHECKS_PAYROLL: 'BIZCHECKS_PAYROLL',
  BUSINESS_ONLINE_PAYROLL: 'BUSINESS_ONLINE_PAYROLL',
  CERIDIAN_DAYFORCE: 'CERIDIAN_DAYFORCE',
  CERIDIAN_POWERPAY: 'CERIDIAN_POWERPAY',
  CEZANNEHR: 'CEZANNEHR',
  CHARLIE_HR: 'CHARLIE_HR',
  CLICKUP: 'CLICKUP',
  COADVANTAGE: 'COADVANTAGE',
  COASTAL_PAYROLL: 'COASTAL_PAYROLL',
  COGNOS_HR: 'COGNOS_HR',
  COLLAGE: 'COLLAGE',
  COMMONWEALTH_PAYROLL_HR: 'COMMONWEALTH_PAYROLL_HR',
  CONNECTPAY: 'CONNECTPAY',
  CPM_ISOLVED: 'CPM_ISOLVED',
  CRESCENT_PAYROLL_SOLUTIONS: 'CRESCENT_PAYROLL_SOLUTIONS',
  DECENT: 'DECENT',
  DEEL: 'DEEL',
  DELUXE: 'DELUXE',
  DM_PAYROLL_SOLUTIONS: 'DM_PAYROLL_SOLUTIONS',
  DOMINION_PAYROLL: 'DOMINION_PAYROLL',
  DOYLE_HCM: 'DOYLE_HCM',
  EDDYHR: 'EDDYHR',
  EMPLICITY: 'EMPLICITY',
  EMPLOYDRIVE: 'EMPLOYDRIVE',
  EMPOWER_HR: 'EMPOWER_HR',
  ENGAGE_PEO: 'ENGAGE_PEO',
  EMPLOYER_FLEXIBLE: 'EMPLOYER_FLEXIBLE',
  ESC: 'ESC',
  EVEREE: 'EVEREE',
  EVOLUTION_PAYROLL_SERVICES: 'EVOLUTION_PAYROLL_SERVICES',
  EXCEL_RESOURCES: 'EXCEL_RESOURCES',
  FLOCK: 'FLOCK',
  FULLSTACK_PEO: 'FULLSTACK_PEO',
  GNA_PARTNERS: 'GNA_PARTNERS',
  GOCO: 'GOCO',
  GREYT_HR: 'GREYT_HR',
  GTM_PAYROLL_SERVICES_EVOLUTION: 'GTM_PAYROLL_SERVICES_EVOLUTION',
  GTM_PAYROLL_SERVICES_ISOLVED: 'GTM_PAYROLL_SERVICES_ISOLVED',
  HEARTLAND: 'HEARTLAND',
  HIGHFLYER_HR: 'HIGHFLYER_HR',
  HORIZONS: 'HORIZONS',
  HRO: 'HRO',
  HRONE: 'HRONE',
  HUBSTAFF: 'HUBSTAFF',
  HUMI: 'HUMI',
  INFINITI_HR: 'INFINITI_HR',
  INFOR: 'INFOR',
  IRIS_HR: 'IRIS_HR',
  ISOLVED: 'ISOLVED',
  KENJO: 'KENJO',
  KEYSTONE_ISOLVED: 'KEYSTONE_ISOLVED',
  MP: 'MP',
  MPAY: 'MPAY',
  MULTIPLIER: 'MULTIPLIER',
  NETCHEX: 'NETCHEX',
  NATURAL_HR: 'NATURAL_HR',
  NEWTEK: 'NEWTEK',
  NEXTEP: 'NEXTEP',
  OASIS: 'OASIS',
  ODOO: 'ODOO',
  ONESOURCE_PAYROLL: 'ONESOURCE_PAYROLL',
  ONPAY: 'ONPAY',
  OPOLIS: 'OPOLIS',
  ORACLE_FUSION: 'ORACLE_FUSION',
  ORACLE_PEOPLESOFT: 'ORACLE_PEOPLESOFT',
  PANTHER: 'PANTHER',
  PARAGON_PAYROLL_ISOLVED: 'PARAGON_PAYROLL_ISOLVED',
  PATRIOT: 'PATRIOT',
  PAYCOR: 'PAYCOR',
  PAYDAY_WORKFORCE_SOLUTIONS: 'PAYDAY_WORKFORCE_SOLUTIONS',
  PAYFIT: 'PAYFIT',
  PAYNW: 'PAYNW',
  PAYPRO_HCS_ISOLVED: 'PAYPRO_HCS_ISOLVED',
  PAYROLL_NETWORK: 'PAYROLL_NETWORK',
  PAYROLL_OFFICE_OF_AMERICA: 'PAYROLL_OFFICE_OF_AMERICA',
  PAYROLL_PLUS_HCM_ISOLVED: 'PAYROLL_PLUS_HCM_ISOLVED',
  PAYROLL_PLUS_HCM_EVOLUTION: 'PAYROLL_PLUS_HCM_EVOLUTION',
  PAYROLL_SOLUTIONS: 'PAYROLL_SOLUTIONS',
  PAY_USA_INC: 'PAY_USA_INC',
  PCS_HCM: 'PCS_HCM',
  PEOPLEFORCE: 'PEOPLEFORCE',
  PEOPLEHUM: 'PEOPLEHUM',
  PEOPLE_LEASE: 'PEOPLE_LEASE',
  PLATINUM_GROUP: 'PLATINUM_GROUP',
  PRESTIGE_PEO: 'PRESTIGE_PEO',
  PRIMEPAY: 'PRIMEPAY',
  PRISM_HR: 'PRISM_HR',
  PROLIANT: 'PROLIANT',
  PROPEL_HR: 'PROPEL_HR',
  PROSOFTWARE: 'PROSOFTWARE',
  PROXUS_HR: 'PROXUS_HR',
  REMOTE: 'REMOTE',
  RESOURCING_EDGE: 'RESOURCING_EDGE',
  RMI_PEO: 'RMI_PEO',
  SAGE_50_QUANTUM_ACCOUNTING: 'SAGE_50_QUANTUM_ACCOUNTING',
  SAGE_50_US_EDITION_ACCOUNTING: 'SAGE_50_US_EDITION_ACCOUNTING',
  SAGE_PAYROLL: 'SAGE_PAYROLL',
  SUCCESSFACTORS: 'SUCCESSFACTORS',
  SAVANT_HCM_EVOLUTION: 'SAVANT_HCM_EVOLUTION',
  SHEAKLEY: 'SHEAKLEY',
  SIMPLOY: 'SIMPLOY',
  SKUAD: 'SKUAD',
  SOLUTION_SERVICES: 'SOLUTION_SERVICES',
  SOUTHEASTPERSONNEL: 'SOUTHEASTPERSONNEL',
  SOURCEONE: 'SOURCEONE',
  STRATEGIC_PAYROLL_SOLUTIONS_ISOLVED: 'STRATEGIC_PAYROLL_SOLUTIONS_ISOLVED',
  SUITE_PEOPLE: 'SUITE_PEOPLE',
  SUREPAYROLL: 'SUREPAYROLL',
  SYNC_HR: 'SYNC_HR',
  THE_PAYROLL_COMPANY: 'THE_PAYROLL_COMPANY',
  THREAD_HCM: 'THREAD_HCM',
  TOAST_PAYROLL: 'TOAST_PAYROLL',
  TRAKSTAR: 'TRAKSTAR',
  UKG_READY: 'UKG_READY',
  UZIO: 'UZIO',
  VELOCITY_GLOBAL: 'VELOCITY_GLOBAL',
  VENSURE_HR: 'VENSURE_HR',
  VFFICIENT: 'VFFICIENT',
  VIEWPOINT_HR_MANAGEMENT_SPECTRUM: 'VIEWPOINT_HR_MANAGEMENT_SPECTRUM',
  VIEWPOINT_HR_MANAGEMENT_VISTA: 'VIEWPOINT_HR_MANAGEMENT_VISTA',
  WAGEPOINT: 'WAGEPOINT',
  WAVE: 'WAVE',
  WEB_HR: 'WEB_HR',
  WHIRKS: 'WHIRKS',
  WORKFORCE_JUNCTION: 'WORKFORCE_JUNCTION',
  WORKLIO: 'WORKLIO',
  WORKZOOM: 'WORKZOOM',
  WURK: 'WURK',
  XENIUM_HR: 'XENIUM_HR',
  XERO: 'XERO',
  ZOHO_PAYROLL: 'ZOHO_PAYROLL',
  FINCH: 'FINCH',
  FIFTEEN_FIVE: 'FIFTEEN_FIVE',
  CULTURE_AMP: 'CULTURE_AMP',
  CARTA: 'CARTA'
};

exports.IntegrationDataType = exports.$Enums.IntegrationDataType = {
  PAYROLL: 'PAYROLL',
  PERFORMANCE: 'PERFORMANCE',
  EQUITY: 'EQUITY'
};

exports.AdminTaskType = exports.$Enums.AdminTaskType = {
  EMAIL_DUPLICATE_FIX: 'EMAIL_DUPLICATE_FIX'
};

exports.AdminTaskStatus = exports.$Enums.AdminTaskStatus = {
  PENDING: 'PENDING',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  DISMISSED: 'DISMISSED',
  COMPLETE: 'COMPLETE'
};

exports.FileUploadType = exports.$Enums.FileUploadType = {
  REPORTS: 'REPORTS',
  RANGE_DATA: 'RANGE_DATA'
};

exports.FileUploaderType = exports.$Enums.FileUploaderType = {
  SYSTEM: 'SYSTEM',
  USER: 'USER'
};

exports.DataImportStatus = exports.$Enums.DataImportStatus = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
};

exports.DataImportType = exports.$Enums.DataImportType = {
  SYNC: 'SYNC',
  UPLOAD: 'UPLOAD'
};

exports.PerformanceProvider = exports.$Enums.PerformanceProvider = {
  FIFTEEN_FIVE: 'FIFTEEN_FIVE',
  CULTURE_AMP: 'CULTURE_AMP',
  UPLOAD: 'UPLOAD'
};

exports.CompensationEventType = exports.$Enums.CompensationEventType = {
  SALARY_CHANGE: 'SALARY_CHANGE',
  BONUS_AWARD: 'BONUS_AWARD',
  TITLE_CHANGE: 'TITLE_CHANGE',
  CUSTOM: 'CUSTOM',
  BONUS_TARGET: 'BONUS_TARGET',
  VARIABLE_TARGET: 'VARIABLE_TARGET'
};

exports.KeyResultType = exports.$Enums.KeyResultType = {
  CURRENCY: 'CURRENCY',
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
  PERCENT: 'PERCENT'
};

exports.BenchmarkingWorkflowStatus = exports.$Enums.BenchmarkingWorkflowStatus = {
  PENDING: 'PENDING',
  GENERATING_CANDIDATES: 'GENERATING_CANDIDATES',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
};

exports.Prisma.ModelName = {
  AuditLog: 'AuditLog',
  ApplicationSetting: 'ApplicationSetting',
  AsyncTask: 'AsyncTask',
  AccessProfile: 'AccessProfile',
  AccessProfileMemberRelationship: 'AccessProfileMemberRelationship',
  Benefit: 'Benefit',
  BenefitsPackage: 'BenefitsPackage',
  BenefitAssignment: 'BenefitAssignment',
  CartaEquityGrant: 'CartaEquityGrant',
  Company: 'Company',
  CompanyOnboardingConfig: 'CompanyOnboardingConfig',
  CustomField: 'CustomField',
  CompensationApproval: 'CompensationApproval',
  CompensationApprovalLevel: 'CompensationApprovalLevel',
  EmailAddress: 'EmailAddress',
  ExchangeRate: 'ExchangeRate',
  StockPrice: 'StockPrice',
  TableSettings: 'TableSettings',
  UserTableState: 'UserTableState',
  User: 'User',
  Range: 'Range',
  Zone: 'Zone',
  Level: 'Level',
  Track: 'Track',
  Job: 'Job',
  Family: 'Family',
  FamilyGroup: 'FamilyGroup',
  Proposal: 'Proposal',
  GrantType: 'GrantType',
  ProposerBudget: 'ProposerBudget',
  Review: 'Review',
  ReviewSetupConfig: 'ReviewSetupConfig',
  AwardLetterTemplate: 'AwardLetterTemplate',
  AwardLetterTemplateSection: 'AwardLetterTemplateSection',
  AwardLetter: 'AwardLetter',
  Integration: 'Integration',
  AccessExtension: 'AccessExtension',
  AdminTask: 'AdminTask',
  FileUpload: 'FileUpload',
  DataImport: 'DataImport',
  LogicRun: 'LogicRun',
  PerformanceCycle: 'PerformanceCycle',
  PerformanceRating: 'PerformanceRating',
  ReviewTableFilterState: 'ReviewTableFilterState',
  CompensationEvent: 'CompensationEvent',
  SystemFieldOverride: 'SystemFieldOverride',
  FieldTablePlacement: 'FieldTablePlacement',
  LogicGraph: 'LogicGraph',
  Objective: 'Objective',
  KeyResult: 'KeyResult',
  Presence: 'Presence',
  PerformanceQuestion: 'PerformanceQuestion',
  PerformanceAnswer: 'PerformanceAnswer',
  LLMPrompt: 'LLMPrompt',
  LlmConversation: 'LlmConversation',
  LlmConversationMessage: 'LlmConversationMessage',
  DataImportApiLog: 'DataImportApiLog',
  Partner: 'Partner',
  BenchmarkingWorkflow: 'BenchmarkingWorkflow',
  BenchmarkResult: 'BenchmarkResult',
  AutoBenchmarkLlmCall: 'AutoBenchmarkLlmCall',
  ManagerHierarchy: 'ManagerHierarchy',
  RangesWithTaxonomy: 'RangesWithTaxonomy'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
