import { useEffect, useMemo, useState } from "react";

import { AnyObject, EmptyObject, ValueOf } from "@hen/stdlib/types";
import _ from "lodash";
import { isDefined, objectKeys } from "ts-extras";

import { stateGraphConfig } from "prisma/cm/outputFromGenerators/posStateGraphConfig/pos-state-graph-config";
import { TAppDataContextValue, useAppData } from "services/app-data";
import {
  ObjectPoolModel,
  ObjectPoolModelId,
  ObjectPoolModelName,
  ObjectPoolEntityMap,
  ObjectPoolEntityMapManifest,
} from "utils/types";

import { useObjectPoolEntities } from "../useGetObjectPoolModelQuery";

type StateGraphModelConfigRelation = {
  hasMany?: true;
  sourceIdKey: string;
  targetObjectName: ObjectPoolModelName;
  targetIdKey: string;
};

type StateGraphModelConfigRelations = {
  [s: string]: StateGraphModelConfigRelation;
};

type _StateGraphModelConfig = {
  objectName: ObjectPoolModelName;
  relations: StateGraphModelConfigRelations;
  hasField: { [s: string]: true };
};

export type ObjectPoolModelToStateGraphConfig = typeof stateGraphConfig;

function getRawEntityMap<TModelName extends ObjectPoolModelName>(
  entityMaps: ObjectPoolEntityMapManifest,
  { objectName }: { objectName: TModelName },
): ObjectPoolEntityMap<TModelName> {
  // @ts-expect-error TS2741
  if (!entityMaps) return { entities: {}, ids: [] };
  const entityMap = entityMaps[objectName];
  // @ts-expect-error TS2741
  if (!entityMap) return { entities: {}, ids: [] };
  return entityMap as ObjectPoolEntityMap<TModelName>;
}

function getRawObject<TModelName extends ObjectPoolModelName>(
  entityMaps: ObjectPoolEntityMapManifest,
  { objectName }: { objectName: TModelName },
  params: { where: { id: string | number } },
): ObjectPoolModel<TModelName> | null {
  const entityMap = entityMaps[objectName];
  const { id, ...whereRest } = params.where;
  const object = id
    ? // @ts-expect-error TS7053
      entityMap.entities[id]
    : !_.isEmpty(whereRest)
      ? _.find(entityMap.entities, whereRest)
      : null;
  if (!object) return null;
  return object as ObjectPoolModel<TModelName>;
}

function getDefaultSnapshotCycleIdScopeForModel<TModelName extends ObjectPoolModelName>(
  parentObject?: AnyObject,
) {
  if (parentObject?.snapshotCycleId)
    return { snapshotCycleId: parentObject.snapshotCycleId } as unknown as Partial<
      ObjectPoolModel<TModelName>
    >;
  return { snapshotCycleId: "NOT_SNAPSHOT" } as unknown as Partial<ObjectPoolModel<TModelName>>;
}

function getDefaultReviewIdScopeForModel<TModelName extends ObjectPoolModelName>(
  parentObject: AnyObject | null,
) {
  if (!parentObject) return null;
  if (parentObject.reviewId) {
    return { reviewId: parentObject.reviewId } as unknown as Partial<ObjectPoolModel<TModelName>>;
  }
  if (parentObject.explicitKind === "review") {
    return { reviewId: parentObject.id } as unknown as Partial<ObjectPoolModel<TModelName>>;
  }
  return null;
}

function getHasSnapshotCycleId<TModelName extends ObjectPoolModelName>(
  objectName: TModelName,
  object?: AnyObject,
): boolean {
  if (object) {
    return Boolean(object.snapshotCycleId);
  }
  const stateGraphConfigForObject = stateGraphConfig[objectName];
  if (!stateGraphConfigForObject) return false;
  return stateGraphConfigForObject.hasField[
    "snapshotCycleId" as keyof typeof stateGraphConfigForObject.hasField
  ];
}

function getDefaultScopeForModel<TModelName extends ObjectPoolModelName>(
  objectName: TModelName,
  includeKey?: keyof ObjectPoolModel<TModelName>,
  parentObject?: AnyObject,
): Partial<ObjectPoolModel<TModelName>> | null {
  /*
   * Special case where we don't want to inherit the scope from the parent.
   * (None of the versioned records will be in the same snapshotCycle scope
   * as the parent.)
   */
  if (includeKey === "versionHistory") return null;

  /*
   * Dynamically figure out the default scope on the object based
   * on its shape.
   *
   * Note that this isn't the most flexible or easy to understand, but in the short
   * term it gets us where we need to go without developers needing to be aware
   * of it.
   */

  if (getHasSnapshotCycleId(objectName, parentObject)) {
    return getDefaultSnapshotCycleIdScopeForModel<TModelName>(parentObject);
  }

  if (parentObject) {
    if (parentObject.reviewId || parentObject.explicitKind === "review") {
      return getDefaultReviewIdScopeForModel<TModelName>(parentObject);
    }
  }
  return null;
}

/*
 * The code in this (and other surrounding files) that is responsible
 * for managing the OP store internals is a bit of a mess. Here's why:
 *
 * - It was introduced with a bunch of tech debt, particularly around types,
 *   but also interface conventions and general code quality / testing.
 * - The intention was to address this tech debt, but we didn't get to it.
 *   (largely because we were't quite aware of the additional complexity
 *   we'd need to support, so alarm bells were quieted in favor of other
 *   more pressing customer fires.)
 * - Additional complexity, primarily indexing (a la "databases"), had to
 *   be introduced to support performance problems, making the problems worse.
 *
 * BEFORE ADDING ADDITIONAL FEATURES, WE MUST RIP THIS ENTIRE SYSTEM
 * OUT IN FAVOR OF AN ACTUAL CLIENT-SIDE DB wherein:
 *
 * - We won't need to manage indexes anymore.
 * - We won't need to be responsible for the code that interacts with our store.
 */

/*
 * In cases where we apply a default index, we can use this value
 * to circumvent the index and allow for an unscoped (and less performant)
 * search, should the data require it. (There aren't many of these cases,
 * so the ugliness is fairly confined, believe it or not. Stil, not intuitive
 * and VERY bug prone.)
 */
export const DEFAULT_INDEX_VALUE = "DEFAULT_INDEX_VALUE";

function getObjectIdsList<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(
  objectEntityMap: ObjectPoolEntityMap<TModelName>,
  params: UseStateTreeCollectionParams<TModelName, TIncludes> = {} as UseStateTreeCollectionParams<
    TModelName,
    TIncludes
  >,
) {
  const { where } = params;
  if (!where) return objectEntityMap.ids || [];

  const { indexes } = objectEntityMap;
  if (!indexes) return objectEntityMap.ids || [];

  const indexKey = where && Object.keys(where).find((key) => indexes[key as keyof typeof indexes]);
  if (!indexKey) return objectEntityMap.ids || [];

  const index = indexes[indexKey as keyof typeof indexes];
  if (!index) return objectEntityMap.ids || [];

  const indexValue = where[indexKey as keyof typeof where];

  if (indexValue === DEFAULT_INDEX_VALUE) return objectEntityMap.ids || [];

  // @ts-expect-error TS2536
  const result = index[indexValue as keyof typeof index];
  return result || objectEntityMap.ids || [];
}

function getRelatedObjects<
  TSourceModelName extends ObjectPoolModelName,
  TTargetModelName extends ObjectPoolModelName = ObjectPoolModelName,
>(
  object: ObjectPoolModel<TSourceModelName>,
  entityMaps: ObjectPoolEntityMapManifest,
  include: unknown, // TODO
  relationConfig: ValueOf<StateGraphModelConfigRelations>,
) {
  const { hasMany, targetIdKey, sourceIdKey } = relationConfig;
  const entityMap = getRawEntityMap<TTargetModelName>(entityMaps, {
    // @ts-expect-error TS2322
    objectName: relationConfig.targetObjectName,
  });

  // TODO: Get collection and target / source id keys conditionally
  // based on presence of through.

  const sourceId = object[sourceIdKey as keyof typeof object];

  if (hasMany) {
    // @ts-expect-error TS2345
    const objectIds = getObjectIdsList(entityMap, include);
    // @ts-expect-error TS7006
    return objectIds.reduce((acc, collEntryId) => {
      // @ts-expect-error TS7053
      const collObj = entityMap.entities[collEntryId];
      if (collObj[targetIdKey as keyof typeof collObj] !== sourceId) return acc;
      return [...acc, collObj];
    }, [] as ObjectPoolModelId<ObjectPoolModelName>[]);
  }

  // @ts-expect-error TS2536
  const relatedObject = entityMap.entities[sourceId];
  return [relatedObject];
}

type ObjectInclude<
  TModelName extends ObjectPoolModelName,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
  TIncludeKey extends keyof TIncludes,
  // @ts-expect-error TS2536
> = ObjectPoolModelToStateGraphConfig[TModelName]["relations"][TIncludeKey]["hasMany"] extends true
  ? Array<
      ObjectWithIncludes<
        // @ts-expect-error TS2536
        ObjectPoolModelToStateGraphConfig[TModelName]["relations"][TIncludeKey]["targetObjectName"],
        // @ts-expect-error TS2344
        TIncludes[TIncludeKey] extends boolean ? EmptyObject : TIncludes[TIncludeKey]["include"]
      >
    >
  : ObjectWithIncludes<
      // @ts-expect-error TS2536
      ObjectPoolModelToStateGraphConfig[TModelName]["relations"][TIncludeKey]["targetObjectName"],
      TIncludes[TIncludeKey] extends boolean
        ? EmptyObject
        : TIncludes[TIncludeKey] extends RecursiveIncludeConfig<
              // @ts-expect-error TS2536
              ObjectPoolModelToStateGraphConfig[TModelName]["relations"][TIncludeKey]["targetObjectName"],
              TIncludeKey
            >
          ? TIncludes[TIncludeKey]["include"]
          : never
    >;

type ObjectIncludes<
  TModelName extends ObjectPoolModelName,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
> = {
  [TIncludeKey in keyof TIncludes]?: ObjectInclude<TModelName, TIncludes, TIncludeKey>;
};

export type ObjectWithIncludes<
  TModelName extends ObjectPoolModelName,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
> = ObjectIncludes<TModelName, TIncludes> & ObjectPoolModel<TModelName>;

function isNonRecursiveInclude(params: unknown): params is true {
  return params === true;
}

function isEmptyIncludeResult(value: unknown) {
  return _.isNil(value) || (_.isArray(value) && value.length === 0);
}

export function getObjectWithIncludes<
  TModelName extends ObjectPoolModelName,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(
  object: ObjectPoolModel<TModelName>,
  entityMaps: ObjectPoolEntityMapManifest,
  { objectName }: { objectName: TModelName },
  params: GetObjectWithIncludesIncludeConfig<
    TModelName,
    TIncludes
  > = {} as GetObjectWithIncludesIncludeConfig<TModelName, TIncludes>,
): ObjectWithIncludes<TModelName, TIncludes> {
  const { include } = params;
  if (_.isNil(object) || !isDefined(include))
    return object as ObjectWithIncludes<TModelName, TIncludes>;

  const stateGraphConfigForObject = stateGraphConfig[objectName];
  const relationConfig =
    stateGraphConfigForObject.relations || ({} as typeof stateGraphConfigForObject.relations);
  const includeKeys = objectKeys(include || ({} as typeof include));

  const includes = includeKeys.reduce(
    (acc, includeKey) => {
      if (!params.include) return acc;
      const rawIncludeConfig = include;
      const relationConfigForInclude = relationConfig[
        includeKey as keyof typeof relationConfig
      ] as ValueOf<StateGraphModelConfigRelations>;
      if (!relationConfigForInclude) {
        console.warn(`Could not access include "${includeKey}" from object "${objectName}"`);
        return acc;
      }

      const { hasMany } = relationConfigForInclude;

      const includeForKey = hasMany
        ? getScopedParams(
            relationConfigForInclude.targetObjectName,
            // @ts-expect-error TS2345
            rawIncludeConfig[includeKey as keyof typeof rawIncludeConfig],
            includeKey,
            object,
          )
        : // @ts-expect-error TS2339
          { include: rawIncludeConfig[includeKey as keyof typeof rawIncludeConfig]?.include };

      const relatedObjects = getRelatedObjects(
        object,
        entityMaps,
        includeForKey,
        relationConfigForInclude,
      );

      let includeResult;
      if (relatedObjects.length === 0) {
        includeResult = hasMany ? [] : null;
      } else {
        // @ts-expect-error TS7006
        const relatedObjectsWithIncludes = relatedObjects.map((relatedObject) => {
          let safeIncludeConfig: GetObjectWithIncludesIncludeConfig<
            typeof relationConfigForInclude.targetObjectName,
            PosQueryIncludeFragment<typeof relationConfigForInclude.targetObjectName>
          > = {
            include: isNonRecursiveInclude(includeForKey) ? {} : includeForKey?.include || {},
          };

          const relatedObjectWithIncludes = getObjectWithIncludes<
            typeof relationConfigForInclude.targetObjectName,
            typeof safeIncludeConfig
          >(
            relatedObject,
            entityMaps,
            { objectName: relationConfigForInclude.targetObjectName },
            safeIncludeConfig,
          );
          return relatedObjectWithIncludes;
        });
        includeResult = hasMany ? relatedObjectsWithIncludes : relatedObjectsWithIncludes[0];
      }

      const result = {
        ...acc,
        // In the event that the include doesn't exist but a fallback value
        // for the value is stamped onto the record we use the fallback instead.
        [includeKey]: isEmptyIncludeResult(includeResult)
          ? // @ts-expect-error TS2536
            object[includeKey] || includeResult
          : includeResult,
      };
      return result;
    },
    {} as ObjectIncludes<TModelName, TIncludes>,
  );
  return {
    ...object,
    ...includes,
  };
}

function getObjectPassFilter<TModelName extends keyof ObjectPoolModelToStateGraphConfig>(
  object: ObjectPoolModel<TModelName>,
  filter?: Partial<ObjectPoolModel<TModelName>>,
): boolean {
  if (!filter) return true;
  const hasMatch = Object.keys(filter).every((key) => {
    // @ts-expect-error TS7053
    return object[key] === filter[key];
  });
  return hasMatch;
}

export function retrieveCollectionWithIncludes<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(
  entityMaps: ObjectPoolEntityMapManifest,
  { objectName }: { objectName: TModelName },
  params: UseStateTreeCollectionParams<TModelName, TIncludes> = {} as UseStateTreeCollectionParams<
    TModelName,
    TIncludes
  >,
): ObjectWithIncludes<TModelName, TIncludes>[] {
  const objectEntityMap = getRawEntityMap<TModelName>(entityMaps, { objectName });
  const objectIds = getObjectIdsList(objectEntityMap, params);
  const collectionWithIncludes = objectIds.reduce(
    // @ts-expect-error TS7006
    (acc, id) => {
      // @ts-expect-error TS7053
      const object = objectEntityMap.entities[id];
      if (!getObjectPassFilter(object, params.where)) return acc;
      const result = params.include
        ? getObjectWithIncludes<TModelName, TIncludes>(object, entityMaps, { objectName }, params)
        : object;
      acc.push(result);
      return acc;
    },
    [] as ObjectWithIncludes<TModelName, TIncludes>[],
  );
  return collectionWithIncludes;
}

export function retrieveObjectWithIncludes<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(
  entityMaps: ObjectPoolEntityMapManifest,
  { objectName }: { objectName: TModelName },
  params: UseStateTreeObjectParams<TModelName, TIncludes> = {} as UseStateTreeObjectParams<
    TModelName,
    TIncludes
  >,
): ObjectWithIncludes<TModelName, TIncludes> {
  const object = getRawObject(entityMaps, { objectName }, params);
  if (!params.include || _.isNil(object))
    return object as ObjectWithIncludes<TModelName, TIncludes>;
  return getObjectWithIncludes<TModelName, TIncludes>(object, entityMaps, { objectName }, params);
}

type TerminalIncludeConfig = true;

type RecursiveIncludeConfig<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludeKey extends keyof ObjectPoolModelToStateGraphConfig[TModelName]["relations"],
> = {
  // TODO: May wish for this to be optional?
  include: PosQueryIncludeFragment<
    ObjectPoolModelToStateGraphConfig[TModelName]["relations"][TIncludeKey] extends StateGraphModelConfigRelation
      ? ObjectPoolModelToStateGraphConfig[TModelName]["relations"][TIncludeKey]["targetObjectName"]
      : never
  >;
};

export type PosQueryIncludeFragment<TModelName extends keyof ObjectPoolModelToStateGraphConfig> = {
  [TIncludeKey in keyof ObjectPoolModelToStateGraphConfig[TModelName]["relations"]]?:
    | TerminalIncludeConfig
    | RecursiveIncludeConfig<TModelName, TIncludeKey>;
};

type BaseUseStateTreeWhereFilterParams<TModelName extends keyof ObjectPoolModelToStateGraphConfig> =
  Partial<ObjectPoolModel<TModelName>>;

export type UseStateTreeCollectionParams<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
  TWhere extends
    BaseUseStateTreeWhereFilterParams<TModelName> = BaseUseStateTreeWhereFilterParams<TModelName>,
> = {
  include?: TIncludes;
  enabled?: boolean;
  where?: TWhere;
  debug?: boolean;
  label?: string;
  freeze?: boolean;
};

type BaseUseStateTreeModelWhereParams<TModelName extends keyof ObjectPoolModelToStateGraphConfig> =
  { id: ObjectPoolModel<TModelName>["id"] };

export type UseStateTreeObjectParams<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
> = {
  include?: TIncludes;
  where: BaseUseStateTreeModelWhereParams<TModelName>;
  enabled?: boolean;
  debug?: boolean;
  label?: string;
  freeze?: boolean;
};

type GetObjectWithIncludesIncludeConfig<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
> = {
  include?: TIncludes;
};

function getScopedParams<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(
  objectName: TModelName,
  rawParams: UseStateTreeCollectionParams<TModelName, TIncludes>,
  includeKey?: keyof ObjectPoolModel<TModelName>,
  object?: AnyObject,
): UseStateTreeCollectionParams<TModelName, TIncludes> {
  const defaultScopeForModel = getDefaultScopeForModel(objectName, includeKey, object);
  if (!defaultScopeForModel) return rawParams;
  const safeRawParams = _.isObject(rawParams) ? rawParams : {};
  return {
    ...safeRawParams,
    where: {
      ...defaultScopeForModel,
      ...(safeRawParams.where || {}),
    },
  };
}

function getPrismaSyncCollection<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(
  objectName: TModelName,
  entityMaps: unknown, // TODO
  rawParams: UseStateTreeCollectionParams<TModelName, TIncludes> & { label?: string },
  appData: TAppDataContextValue,
) {
  const startTimestamp = rawParams.debug ? performance.now() : 0;
  const enabled = _.isBoolean(rawParams?.enabled) ? rawParams.enabled : true;
  const params = getScopedParams(objectName, rawParams);
  const collection =
    enabled && appData.isReady
      ? // @ts-expect-error TS2345
        retrieveCollectionWithIncludes<TModelName, TIncludes>(entityMaps, { objectName }, params)
      : ([] as ObjectWithIncludes<TModelName, TIncludes>[]);
  const endTimestamp = params.debug ? performance.now() : 0;
  if (params.debug) {
    console.log("measured_interaction", {
      name: "getPrismaSyncCollection",
      duration: endTimestamp - startTimestamp,
    });
  }
  return collection;
}

export function generateUsePrismaObjectSyncFindMany<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
>(objectName: TModelName) {
  function useCollection<TIncludes extends PosQueryIncludeFragment<TModelName>>(
    params: UseStateTreeCollectionParams<TModelName, TIncludes> & { label?: string } = {},
  ) {
    const appData = useAppData();
    const entityMaps = useObjectPoolEntities();
    const { debug, label, freeze } = params;
    const [result, setResult] = useState(
      getPrismaSyncCollection(objectName, entityMaps, params, appData),
    );

    useEffect(() => {
      if (freeze) {
        if (debug) {
          console.log(`${objectName} - findMany (${label || "unknown"}) - frozen`);
        }
        return;
      }
      const collection = getPrismaSyncCollection(objectName, entityMaps, params, appData);
      if (debug) {
        console.log(`${objectName} - findMany (${label || "unknown"}) - queried result`);
      }
      if (_.isEqual(result, collection)) return;
      setResult(collection);
    }, [entityMaps, params.enabled, appData, params, result, debug, label, freeze]);

    // Return a clone so in the event that the output is mutated, the mutation
    // does not affect the comparison above. This is something we should avoid,
    // but the bugs are bad enough that we provide defense nonetheless.
    return useMemo(() => {
      const clone = _.cloneDeep(result);
      if (debug) {
        console.log(`${objectName} - findMany (${label || "unknown"}) - cloned result`);
      }
      return clone;
    }, [result, debug, label]);
  }
  return useCollection;
}

export function getPrismaSyncObject<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(
  objectName: TModelName,
  entityMaps: unknown, // TODO
  params: UseStateTreeObjectParams<TModelName, TIncludes>,
  appData: TAppDataContextValue,
) {
  const startTimestamp = params.debug ? performance.now() : 0;
  const enabled = _.isBoolean(params?.enabled) ? params.enabled : true;
  const object =
    enabled && appData.isReady
      ? // @ts-expect-error TS2345
        retrieveObjectWithIncludes<TModelName, TIncludes>(entityMaps, { objectName }, params)
      : null;
  const endTimestamp = params.debug ? performance.now() : 0;
  if (params.debug) {
    console.log("measured_interaction", {
      name: "getPrismaSyncObject",
      duration: endTimestamp - startTimestamp,
    });
  }
  return object;
}

function useObjectByName<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
  TIncludes extends PosQueryIncludeFragment<TModelName>,
>(objectName: TModelName, params: UseStateTreeObjectParams<TModelName, TIncludes>) {
  const appData = useAppData();
  const entityMaps = useObjectPoolEntities();
  const [result, setResult] = useState<ObjectWithIncludes<TModelName, TIncludes> | null>(
    getPrismaSyncObject(objectName, entityMaps, params, appData),
  );
  const { isReady } = appData;
  useEffect(() => {
    const object = getPrismaSyncObject(objectName, entityMaps, params, appData);
    if (_.isEqual(object, result)) return;
    setResult(object);
  }, [entityMaps, params.enabled, isReady, params, result, appData, objectName]);

  // Return a clone so in the event that the output is mutated, the mutation
  // does not affect the comparison above. This is something we should avoid,
  // but the bugs are bad enough that we provide defense nonetheless.
  return useMemo(() => _.cloneDeep(result), [result]);
}

export function generateUsePrismaObjectSyncFindFirst<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
>(objectName: TModelName) {
  function useObject<TIncludes extends PosQueryIncludeFragment<TModelName>>(
    params: UseStateTreeObjectParams<TModelName, TIncludes>,
  ) {
    const object = useObjectByName(objectName, params);
    return object;
  }
  return useObject;
}

export function generateUsePrismaObjectSyncFindFirstOrThrow<
  TModelName extends keyof ObjectPoolModelToStateGraphConfig,
>(objectName: TModelName) {
  function useObjectOrThrow<TIncludes extends PosQueryIncludeFragment<TModelName>>(
    params: UseStateTreeObjectParams<TModelName, TIncludes>,
  ) {
    const object = useObjectByName(objectName, params);
    if (!object) throw Error(`Object ${objectName} not found`);
    return object;
  }
  return useObjectOrThrow;
}
