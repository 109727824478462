import { PartnerId } from "prisma/cm/client";

export function getBelongsToFifteenFivePartner<
  T extends {
    company:
      | {
          partnerId: PartnerId | null;
        }
      | null
      | undefined;
  },
>(user: T | null | undefined) {
  return getIsFifteenFivePartner(user?.company);
}

export function getIsFifteenFivePartner<
  T extends {
    partnerId: PartnerId | null;
  },
>(company: T | null | undefined) {
  return company?.partnerId === PartnerId.FIFTEEN_FIVE;
}
