import { useCallback, useMemo, useRef } from "react";

import { datadogRum } from "@datadog/browser-rum";
import afterFrame from "afterframe";
import _ from "lodash";
import { NavigateOptions, To, useNavigate } from "react-router-dom";

import { DatadogSessionUserAttrs, getDatadogSessionUserAttrs } from "lib/datadog";

import { getEnableFrontendPerformanceLogging } from "../pages/dev-tools/feature-toggles";
import { useAuthenticatedUserFromReduxStore } from "../services/user";

type InteractionMetaExtraByName = {
  open_model_drawer: {
    modelName: string;
    action: "edit" | "create";
    activeTab?: string;
  };
  change_nominal_input: {
    field?: string;
  };
  change_data_explorer_query: {};
  proposer_view_next: {
    destination: string;
  };
  navigate: {
    destination: string;
    category?: string;
  };
  show_table: {
    tableName?: string;
  };
  change_table_filter: {
    columnId?: string;
    tableName?: string;
  };
  update_eligibility: {
    columnId: string;
  };
  resolve_object_pool_transactions: {};
};

type InteractionName = keyof InteractionMetaExtraByName;
type InteractionMeta<TName extends InteractionName> = InteractionMetaExtraByName[TName] & {
  usr?: DatadogSessionUserAttrs;
};

type EndInteractionResult = {
  duration: number;
};

type InteractionInstance = {
  end: () => EndInteractionResult;
  endAfterFrame: () => void;
  endAfterFrameAsync: () => Promise<EndInteractionResult>;
};

/*
 * Measures time between when the function is manually invoked (eg. on user
 * interaction) and when the next frame is rendered.
 *
 * @see https://3perf.com/blog/react-monitoring
 */
function privateMeasureInteraction<TName extends InteractionName>(
  name: TName,
  meta?: InteractionMeta<TName>,
): InteractionInstance {
  // performance.now() returns the number of ms
  // elapsed since the page was opened
  const startTimestamp = performance.now();

  function end(): EndInteractionResult {
    const endTimestamp = performance.now();
    const duration = endTimestamp - startTimestamp;
    const verbose = getEnableFrontendPerformanceLogging();
    const context = {
      ...meta,
      name,
      duration,
    };
    if (verbose) {
      console.log("measured_interaction", { name, duration, meta });
    }
    datadogRum.addAction("measured_interaction", context);
    return { duration };
  }
  function endAfterFrame() {
    afterFrame(() => {
      end();
    });
  }
  async function endAfterFrameAsync() {
    const promise = new Promise<EndInteractionResult>((resolve) => {
      afterFrame(() => {
        const result = end();
        resolve(result);
      });
    });
    return promise;
  }
  return {
    end,
    endAfterFrame,
    endAfterFrameAsync,
  };
}

export function useMeasureInteraction() {
  const user = useAuthenticatedUserFromReduxStore();
  const measureInteraction = useCallback(
    <TName extends InteractionName>(name: TName, meta?: InteractionMeta<TName>) => {
      if (user) {
        meta = meta || ({} as InteractionMeta<TName>);
        meta.usr = getDatadogSessionUserAttrs(user);
      }
      return privateMeasureInteraction(name, meta);
    },
    [user],
  );
  return measureInteraction;
}

export function useMeasuredNavigate(category?: string) {
  const navigate = useNavigate();
  const measureInteraction = useMeasureInteraction();

  const measuredNavigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      const destinationStr = _.isString(to) ? to : [to.pathname, to.search, to.hash].join("");
      const interaction = measureInteraction("navigate", {
        destination: destinationStr,
        category,
      });
      navigate(to, options);
      interaction.endAfterFrame();
    },
    [category, measureInteraction, navigate],
  );
  return measuredNavigate;
}

export function useMeasureRenderDuration<TName extends InteractionName>(
  name: TName,
  meta?: InteractionMeta<TName>,
) {
  const measureInteraction = useMeasureInteraction();
  const interactionRef = useRef(measureInteraction(name, meta));
  const endedRef = useRef(false);
  const complete = useCallback(() => {
    const interaction = interactionRef.current;

    const ended = endedRef.current;
    // Prevent multiple calls to `complete` from ending the interaction multiple times
    if (ended) return;
    endedRef.current = true;

    interaction.end();
  }, []);
  const measureRenderDuration = useMemo(() => ({ complete }), [complete]);
  return measureRenderDuration;
}

export function useMeasureDuration<TName extends InteractionName>(
  name: TName,
  meta?: InteractionMeta<TName>,
) {
  const measureInteraction = useMeasureInteraction();
  const interactionRef = useRef<InteractionInstance | null>(null);

  const start = useCallback(() => {
    interactionRef.current = measureInteraction(name, meta);
  }, [measureInteraction, name, meta]);

  const complete = useCallback(() => {
    const interaction = interactionRef.current;
    if (!interaction) {
      throw Error("Interaction not started");
    }
    interaction.end();
    interactionRef.current = null;
  }, []);
  const measureDuration = useMemo(() => ({ start, complete }), [start, complete]);
  return measureDuration;
}
