import { MantineTheme, Title } from "@mantine/core";

import { fontStacks, fontWeights } from "../../tokens";

export const headingsThemeSettings: MantineTheme["headings"] = {
  fontFamily: fontStacks.inter,
  fontWeight: fontWeights.semiBold + "px",
  textWrap: "pretty",
  sizes: {
    // TODO: Manage 2xl
    // 2xl: {
    //   fontSize: "60px",
    //   lineHeight: "68px", // 113%
    // },

    // xl
    h1: {
      fontSize: "48px",
      lineHeight: "54px",
      // @ts-expect-error TS2322
      fontWeight: fontWeights.semiBold,
    },
    // lg
    h2: {
      fontSize: "40px",
      lineHeight: "48px",
      // @ts-expect-error TS2322
      fontWeight: fontWeights.medium,
    },
    // md
    h3: {
      fontSize: "24px",
      lineHeight: "32px",
      // @ts-expect-error TS2322
      fontWeight: fontWeights.medium,
    },
    // sm
    h4: {
      fontSize: "20px",
      lineHeight: "26px",
      // @ts-expect-error TS2322
      fontWeight: fontWeights.medium,
    },
    // xs
    h5: {
      fontSize: "16px",
      lineHeight: "24px",
      // @ts-expect-error TS2322
      fontWeight: fontWeights.medium,
    },

    // 2xs
    h6: {
      fontSize: "14px",
      lineHeight: "21px",
      // @ts-expect-error TS2322
      fontWeight: fontWeights.medium,
    },
  },
};

const styleOverrides = {
  h1: {
    letterSpacing: "-0.04em",
  },
  h2: {
    letterSpacing: "-0.04em",
  },
  h3: {
    letterSpacing: "-0.02em",
  },
  h4: {
    letterSpacing: "-0.02em",
  },
  h5: {
    letterSpacing: "-0.02em",
  },
  h6: {
    letterSpacing: "-0.01em",
  },
};

export const titleComponentSettings: MantineTheme["components"]["Title"] = Title.extend({
  defaultProps: {
    c: "gray.8",
  },
});

export function getHeadingStyles(
  theme: MantineTheme,
  size: keyof MantineTheme["headings"]["sizes"],
) {
  return {
    fontFamily: headingsThemeSettings.fontFamily,
    ...headingsThemeSettings.sizes[size],
    color: theme.colors.gray[8],
  };
}

// export const getHeadingStyles = _.memoize(_getHeadingStyles);
